/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Input,
  Label,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
import Select from 'react-select';

// Redux
import { connect } from 'react-redux';
import {
  list_subs,
  edit,
  cancel_,
  list_device,
  unTagDevice,
  delete_,
  extendedSub,
} from '../../store/subs/actions';
import { buildTitle } from 'Apps';
const timeOptions = [
  { label: '1 Bulan', value: 1 },
  { label: '2 Bulan', value: 2 },
  { label: '3 Bulan', value: 3 },
  { label: '4 Bulan', value: 4 },
  { label: '5 Bulan', value: 5 },
  { label: '6 Bulan', value: 6 },
  { label: '7 Bulan', value: 7 },
  { label: '8 Bulan', value: 8 },
  { label: '9 Bulan', value: 9 },
  { label: '10 Bulan', value: 10 },
  { label: '11 Bulan', value: 11 },
  { label: '12 Bulan', value: 12 },
];
class RoleList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.navRef = React.createRef();
    this.state = {
      modal: false,
      modalNav: false,
      modalExtend: false,
      Role: [],
      deviceBody: [],
      dynamic_title: '',
      dynamic_description: '',
      column: [
        {
          dataField: 'id',
          text: 'id',
          hidden: 'true',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.id}</>;
          },
        },
        {
          dataField: 'label',
          text: 'Name',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.label}</>;
          },
        },
        {
          dataField: 'subs',
          text: 'Subs',
          editor: {
            type: Type.CHECKBOX,
            // value: 'On:Off'
          },
          headerStyle: () => {
            return { width: '80px' };
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                <Input
                  type="checkbox"
                  // disabled
                  defaultChecked={safeDeepGet(row, 'subs', 'off') === 'on'}
                />
              </>
            );
          },
        },
      ],
      RoleListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'key',
          text: 'Subs ID',
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">{row.key}</p>
            </>
          ),
        },
        {
          dataField: 'business_point',
          text: 'Business Point',
          formatter: (cellContent, row) => (
            <>
              {row.status ? (
                <b>{row.status}</b>
              ) : (
                <Button
                  className="font-16 btn-block btn btn-primary"
                  // onClick={this.handleDetailNav}
                  onClick={() => this.handleDetailNav(row)}
                >
                  Detail
                </Button>
              )}
            </>
          ),
        },
        {
          dataField: 'end_ts',
          text: 'End Subscription',
          formatter: (cellContent, row) => (
            <>
              <p>
                {this.handleValidDate(
                  safeDeepGet(row, 'end_ts', safeDeepGet(row, 'duration'))
                )}
              </p>
            </>
          ),
        },
        {
          dataField: 'action',
          text: 'Action',
          formatter: (cellContent, row) => (
            <>
              {row.status == 'Create' ||
              row.status == 'On Progress' ||
              row.status == 'Extended' ? (
                <Button
                  className="font-16 btn-block btn btn-success"
                  // onClick={this.handleDetailNav}
                  onClick={() => this.handleQrcodePayment(row)}
                >
                  Bayar
                </Button>
              ) : row.status == 'Cancelled' ||
                row.status == 'Expired' ? null : (
                <>
                  <Button
                    className="font-16 btn-block btn btn-info"
                    onClick={() => this.handleExtend(row)}
                  >
                    Extend
                  </Button>{' '}
                  <Button
                    className="font-16 btn-block btn btn-danger"
                    // onClick={this.handleDetailNav}
                    onClick={() => this.handleConfirmRegister(row)}
                  >
                    Delete
                  </Button>
                </>
              )}{' '}
              {row.status == 'On Progress' ? (
                <Button
                  className="font-16 btn-block btn btn-danger"
                  // onClick={this.handleDetailNav}
                  onClick={() => this.handleCancelPayment(row)}
                >
                  Batalkan
                </Button>
              ) : null}
            </>
          ),
        },
      ],
    };
    this.handleUnRegister = this.handleUnRegister.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleExtend = this.handleExtend.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.handleCancelPayment = this.handleCancelPayment.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.handleDetailNav = this.handleDetailNav.bind(this);
    this.handleLocalDate = this.handleLocalDate.bind(this);
    this.handleLocalDate = this.handleLocalDate.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleQrcodePayment = this.handleQrcodePayment.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.toggleExtend = this.toggleExtend.bind(this);
  }

  handleValidDate = date => {
    if (date != undefined) {
      var myDate = new Date(date);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const date1 = myDate.toLocaleDateString('en-GB', options);
      const time = myDate.toLocaleTimeString('en-GB');
      return date1 + ' ' + time;
    }
    return null;
  };
  handleLocalDate() {
    let localDate = '';
    var myDate = new Date();
    const day = myDate.getDate().toString();
    const month = (myDate.getMonth() + 1).toString();
    const year = myDate.getFullYear().toString();
    const date = year + month + day;

    const hours = myDate.getHours().toString();
    const minutes = myDate.getMinutes().toString();
    const seconds = myDate.getSeconds().toString();
    const time = hours + minutes + seconds;
    localDate = date + time;
    return localDate;
  }
  handleExtend = row => {
    const key = safeDeepGet(row, 'key', '');
    const old_key = row.key;
    const new_key = 'SUBS' + this.handleLocalDate();
    const end_date = row.end_ts;
    this.setState({ old_key, end_date, new_key, key });
    this.toggleExtend();
  };
  handleTimeChange = values => {
    const date = new Date(this.state.end_date);
    const localDate = new Date(date.setMonth(date.getMonth() + values.value));
    const day = localDate.getDate().toString();
    const month = localDate.getMonth();
    const year = localDate.getFullYear().toString();
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const stringDate = day + ' ' + monthNames[month] + ' ' + year;
    this.setState({ localDate: stringDate });
    this.setState({ duration: values.value });
  };
  handleExtendValid = (e, values) => {
    const date = new Date(this.state.end_date);
    const new_end_date = new Date(
      date.setMonth(date.getMonth() + this.state.duration)
    );

    const submitedValues = {
      ...values,
      end_ts: new_end_date.getTime(),
      duration: this.state.duration,
    };
    this.props.ExtendSub(submitedValues);
  };
  toggleExtend() {
    this.setState(prevState => ({
      modalExtend: !prevState.modalExtend,
    }));
  }
  handleCancleClick() {
    if (this.state.modalExtend) {
      this.toggleExtend();
    } else {
      this.toggleNav();
    }
  }
  handleDeleteClick = (id, name) => {
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Edit',
      dynamic_description: 'Tags has Been Edited.',
    });
    this.handleValidSubmit();
    this.toggleNav();
  };
  handleConfirmDeleteClick = (e, values) => {
    this.setState({
      confirm_alert: true,
    });
  };
  toggleNav() {
    this.setState(prevState => ({
      modalNav: !prevState.modalNav,
    }));
  }
  componentDidUpdate(prevProps) {
    if (this.props.cancelResult !== prevProps.cancelResult) {
      const code = safeDeepGet(
        this.props,
        ['cancelResult', 'code'],
        Result.FAILED
      );
      if (code == Result.SUCCESS) {
        this.showToast('Berhasil Membatalkan Pembayaran');
        setTimeout(() => {
          this.props.LoadSubsList();
        }, 2000);
      }
    }
    if (this.props.extendResult !== prevProps.extendResult) {
      const code = safeDeepGet(
        this.props,
        ['extendResult', 'code'],
        Result.FAILED
      );
      if (code == Result.SUCCESS) {
        this.showToast('Berhasil memperpanjang durasi subcription');
        this.toggleExtend();
        this.props.LoadSubsList();
      }
    }
    if (this.props.listDeviceResult !== prevProps.listDeviceResult) {
      const code = safeDeepGet(
        this.props,
        ['listDeviceResult', 'code'],
        Result.FAILED
      );
      if (code == Result.SUCCESS) {
        const deviceBody = safeDeepGet(
          this.props,
          ['listDeviceResult', 'body'],
          []
        );
        this.setState({ deviceBody });
        if (deviceBody.length > 0) {
          this.toggleNav();
        }
      }
    }
  }
  componentDidMount() {
    // Load business point
    this.props.LoadSubsList();
  }
  handleValidSubmit = (e, values) => {
    const { EditSubs } = this.props;

    const dataTabel = this.navRef.current.props.data;
    const editValues = {
      key: this.state.key,
      table: dataTabel,
    };
    EditSubs(editValues);
  };
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/subs/device');
  };
  handleDetailNav = row => {
    const key = safeDeepGet(row, 'key', '');
    this.setState({ key });
    this.props.LoadSubsDeviceList(key);
  };
  handleUnRegister = key => {
    this.props.DeleteSubs(key);
    this.setState({
      confirm_delete: false,
      success_dlg_delete: true,
      dynamic_title: 'Deleted',
      dynamic_description: 'Subscription has Been Deleted.',
    });
    setTimeout(() => {
      this.props.LoadSubsList();
    }, 2000);
  };
  handleConfirmRegister = row => {
    const key = safeDeepGet(row, 'key', '');
    this.setState({ key, confirm_delete: true });
    this.props.LoadSubsList();
  };
  handleQrcodePayment = row => {
    const url = safeDeepGet(row, 'url', []);
    window.open(url);
    setTimeout(() => {
      location.reload();
    }, 3000);
  };
  handleCancelPayment = row => {
    const order_id = safeDeepGet(row, 'order_id', []);
    this.props.CancelPayment(order_id);
  };

  handleEditClick = row => {
    const id = safeDeepGet(row, 'id', '');
    this.props.history.push('/role/edit/' + id);
  };

  showToast(msg) {
    var toastType = 'success';
    var message = msg;
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const disabled = safeDeepGet(this.props, ['result', 'disabled'], false);
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const { column, deviceBody, new_key, old_key } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle("Subscription List")} </title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            {this.state.confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, Edit it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() =>
                  this.handleDeleteClick(this.state.device_id, this.state.name)
                }
                onCancel={() =>
                  this.setState({
                    confirm_alert: false,
                  })
                }
              >
                You won&apos;t be able to revert this!
              </SweetAlert>
            ) : null}
            {this.state.success_dlg_delete ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg_delete: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            {this.state.confirm_delete ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, Edit it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => this.handleUnRegister(this.state.key)}
                onCancel={() =>
                  this.setState({
                    confirm_delete: false,
                  })
                }
              >
                You won&apos;t be able to revert this!
              </SweetAlert>
            ) : null}
            <Breadcrumbs title="Subscription" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.RoleListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.RoleListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                      disabled={disabled}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Subscription
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modalNav}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.modalNav} tag="h4">
                        {/* <ModalHeader toggle={this.toggleNav} tag="h4"> */}
                        {'Detail Device ' + this.state.key}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleConfirmDeleteClick}>
                          <BootstrapTable
                            keyField="id"
                            bootstrap4={true}
                            data={deviceBody}
                            cellEdit={cellEditFactory({
                              mode: 'click',
                              blurToSave: true,
                            })}
                            columns={column}
                            bordered={false}
                            ref={this.navRef}
                          />
                          <div className="d-flex flex-wrap gap-2 float-end mb-4">
                            <Button
                              onClick={this.handleCancleClick}
                              color="secondary"
                            >
                              Cancel
                            </Button>{' '}
                            <Button type="submit" color="primary">
                              Simpan
                            </Button>
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                    <Modal
                      size="lg"
                      isOpen={this.state.modalExtend}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.modalExtend} tag="h4">
                        {'Extend PO ' + this.state.key}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleExtendValid}>
                          <Row>
                            <Col sm="6">
                              <Label htmlFor="old_key">Old PO : </Label>
                              <AvField
                                type="text"
                                id="old_key"
                                name="old_key"
                                placeholder="Masukan name..."
                                className="form-control"
                                value={old_key}
                                validate={{
                                  required: { value: true },
                                }}
                                disabled={true}
                              />
                            </Col>
                            <Col sm="6">
                              <Label htmlFor="new_key">New PO : </Label>
                              <AvField
                                type="text"
                                id="new_key"
                                name="new_key"
                                placeholder="Masukan name..."
                                className="form-control"
                                value={new_key}
                                validate={{
                                  required: { value: true },
                                }}
                                disabled={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="6">
                              <Label htmlFor="name">Name : </Label>
                              <AvField
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Masukan name..."
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                            <Col sm="6">
                              <Label htmlFor="phone">No Handphone : </Label>
                              <AvField
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder="Masukan No Hp..."
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                          </Row>

                          <FormGroup className="mb-3 select2-container">
                            <Label>Duration</Label>
                            <Select
                              className="mb-3"
                              options={timeOptions}
                              onChange={this.handleTimeChange}
                            ></Select>
                          </FormGroup>
                          <p>
                            Durasi akan di perpajang Hingga{' '}
                            {this.state.localDate}
                          </p>
                          <div className="d-flex flex-wrap gap-2 float-end mb-4">
                            <Button
                              onClick={this.handleCancleClick}
                              color="secondary"
                            >
                              Cancel
                            </Button>{' '}
                            <Button type="submit" color="primary">
                              Simpan
                            </Button>
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['subs', 'list_subs'], {}),
  loading: safeDeepGet(state, ['role', 'loading'], true),
  editResult: safeDeepGet(state, ['subs', 'edit'], {}),
  listDeviceResult: safeDeepGet(state, ['subs', 'list_device'], {}),
  cancelResult: safeDeepGet(state, ['subs', 'cancel_'], {}),
  deleteResult: safeDeepGet(state, ['subs', 'delete'], {}),
  extendResult: safeDeepGet(state, ['subs', 'extendedSub'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadSubsList: () => dispatch(list_subs()),
  EditSubs: payload => dispatch(unTagDevice(payload)),
  CancelPayment: payload => dispatch(cancel_(payload)),
  LoadSubsDeviceList: payload => dispatch(list_device(payload)),
  DeleteSubs: payload => dispatch(delete_(payload)),
  ExtendSub: payload => dispatch(extendedSub(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
