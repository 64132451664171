/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  InputGroup,
  Label,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, delete_, create, cancel_ } from '../../store/coupon/actions';
 
import { computeSegDraggable } from '@fullcalendar/core';
import { buildTitle } from 'Apps';
class CouponList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const appId = safeGet(this.props.match.params, 'appId');
    this.refDatePicker = React.createRef();

    this.state = {
      modal: false,
      refiller: [],
      appId,
      CouponListColumns: [
        {
          text: 'uid',
          dataField: 'uid',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.uid}</>,
        },
        {
          text: 'Coupon Code',
          dataField: 'code',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.code}
                </Link>
              </h5>
            </>
          ),
        },
        {
          text: 'Coupon Discount',
          dataField: 'discount',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.discount}
                </Link>
              </h5>
            </>
          ),
        },

        {
          dataField: 'created',
          text: 'Created',
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                {this.handleLocalDate(safeGet(row, 'created_ts', '-'))}
              </h5>
            </>
          ),
        },
        {
          dataField: 'exp',
          text: 'Expired',
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                {this.handleLocalDate(safeGet(row, 'expired_ts', '-'))}
              </h5>
            </>
          ),
        },
        {
          dataField: 'status',
          text: 'Active',
          formatter: (cellContent, row) => (
            <>
              <button
                type="button"
                style={{ borderRadius: '23px', fontSize: '12' }}
                className={
                  'btn  btn-sm btn-' +
                  (row.status == 'active' ? 'success' : 'danger')
                }
              >
                {row.status}
              </button>
            </>
          ),
        },

        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, data) => (
            <div className="d-flex gap-3">
              {/* <Tippy content={'Edit'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleEditClick(data)}
                  ></i>
                </Link>
              </Tippy> */}
              <Tippy content={'Cancel'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-window-close font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleCancel(data)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleDeleteClick(data)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleLocalDate(date) {
    let localDate = '';
    if (date == '-' || date == '' || date == null || date == undefined) {
      localDate = date;
    } else {
      var myDate = new Date(date);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const date1 = myDate.toLocaleDateString('en-GB', options);
      const time = myDate.toLocaleTimeString('en-GB');
      localDate = date1 + ' ' + time;
    }
    return localDate;
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    // Load business point
    this.props.LoadCouponList();
  }
  /* Handle click */
  handleAddClick = arg => {
    this.toggle();
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast();
        this.toggle();
        this.props.LoadCouponList(this.state.appId);
        // setTimeout(window.location.reload(), 4000);
      }
    }
    if (this.props.deleteResult !== prevProps.deleteResult) {
      const code = safeGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToastDelete();
        this.props.LoadCouponList();

        // this.props.LoadCouponList(this.state.appId);
        // setTimeout(window.location.reload(), 4000);
      }
    }
    if (this.props.cancelResult !== prevProps.cancelResult) {
      const code = safeGet(
        this.props,
        ['cancelResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToastCancel();

        this.props.LoadCouponList();
        // setTimeout(window.location.reload(), 4000);
      }
    }
  }
  handleValidSubmit(event, values) {
    const { exp_ts } = this.state;
    const submittedValues = { ...values, exp_ts: exp_ts };
    this.props.CreateCoupon(submittedValues);
  }
  handleCancel(row) {
    const submittedValues = { ...row };
    this.props.CancelCoupon(submittedValues);
  }
  handleDeleteClick = row => {
    const submittedValues = { ...row };
    this.props.DeleteCoupon(submittedValues);
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Membuat Kupon';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastCancel() {
    var toastType = 'success';
    var message = 'Berhasil Membatalkan Kupon';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastDelete() {
    var toastType = 'success';
    var message = 'Berhasil Menghapus Kupon';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handleDateRange = date => {
    const ts = date[0].getTime();
    this.setState({ date, exp_ts: ts });
  };
  render() {
    // TODO Loading
    const list = safeGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'email', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };

    const { date } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle("Coupon List")}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Coupon" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="code"
                      columns={this.state.CouponListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="code"
                          columns={this.state.CouponListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Coupon
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Create A new Coupon'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <Row form>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label htmlFor="discount">Discount : </Label>
                                <AvField
                                  type="number"
                                  id="discount"
                                  name="discount"
                                  placeholder="Masukan Discount..."
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                            </Col>
                            <Col>
                              <Label htmlFor="expired">Expired : </Label>
                              <InputGroup className="mb-3">
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Date"
                                  options={{
                                    mode: 'single',
                                    // dateFormat: 'Y-m-d',
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }}
                                  value={date}
                                  onChange={this.handleDateRange}
                                  ref={this.refDatePicker}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Submit
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeGet(state, ['coupon', 'list'], {}),
  createResult: safeGet(state, ['coupon', 'create'], {}),
  loading: safeGet(state, ['coupon', 'loading'], true),
  updateResult: safeGet(state, ['coupon', 'update'], {}),
  deleteResult: safeGet(state, ['coupon', 'delete'], {}),
  cancelResult: safeGet(state, ['coupon', 'cancel_'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadCouponList: () => dispatch(list()),
  UpdateRefiller: payload => dispatch(update(payload)),
  DeleteCoupon: payload => dispatch(delete_(payload)),
  CreateCoupon: payload => dispatch(create(payload)),
  CancelCoupon: payload => dispatch(cancel_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponList);
