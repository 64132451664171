/* eslint-disable semi */
/* Import */
import firebase from 'firebase/compat/app';


export default class ProjectConfig {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('project_handle');
  }

  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };

}
