/* eslint-disable no-unused-vars */

/* eslint-disable semi */

/* Import */
import firebase from 'firebase/compat/app';
import Base from './base';
export default class Account extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'account');
  }

  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  create = (payload, uid) => {
    return new Promise((resolve, reject) => {
      delete payload.pass;
      const insertedPayload = {};
      for (const key in payload) {
        if (key === 'name' ) {
          insertedPayload[key] = payload[key];
        }
      }
      const email = payload['email'].toLowerCase();
      insertedPayload['email'] = email
      insertedPayload['uid'] = uid
      insertedPayload['role'] = payload['role'].value
      this.collection
      .doc(email)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = name => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('name','==' ,name)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };
  getRole = email => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('email','==' ,email)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'email' || key === 'name' || key === 'role') {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };


  delete_ = id => {
    this.collection.doc(id).delete();
  };
}
