/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Spinner,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { initCreate, create, read, update } from '../../store/account/actions';
import { NAME_PROJECT, buildTitle } from 'Apps';
class CreateAccount extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let name = null;
    const path = this.props.match.path;
    if (path.includes('/edit/')) {
      isEdit = true;
      name = safeDeepGet(this.props.match.params, 'id');
    }
    this.state = {
      isEdit,
      name,
      roleOptions: [],
      loading: false,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.handelChangeRole = this.handelChangeRole.bind(this);
    this.showToast = this.showToast.bind(this);
  }
  handelChangeRole(value) {
    const { isEdit } = this.state;
    if (!isEdit) {
      this.setState({ role: value });
    } else {
      this.setState({ role: value.value });
    }
  }
  handleCancleClick() {
    this.props.history.push(`/account/list`);
  }
  handleValidSubmit(event, values) {
    const { role, isEdit } = this.state;
    this.setState({ loading: true });

    const submittedValues = { ...values, role };
    if (!isEdit) {
      this.props.CreateAccount(submittedValues);
    } else {
      this.props.UpdateAccount(submittedValues);
    }
  }
  componentDidMount() {
    const { isEdit, name } = this.state;
    // Load Account
    if (!isEdit) {
      this.props.InitCreateAccount();
    } else {
      this.props.ReadAccount(name);
      this.props.InitCreateAccount();
    }
  }
  showToast() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'success';
      var message = 'Berhasil Update Account';
    } else {
      var toastType = 'success';
      var message = 'Berhasil Tambah Account';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed() {
    const { isEdit } = this.state;
    var toastType = 'error';
    var message = 'Gagal Tambah Account';
    if (isEdit) {
      toastType = 'error';
      message = 'Gagal Update Account';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;
    if (this.props.initCreateResult !== prevProps.initCreateResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['initCreateResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS || code === Result.HTTP.OK) {
        const roleOptions = safeDeepGet(
          this.props,
          ['initCreateResult', 'body'],
          []
        );
        this.setState({
          roleOptions,
        });
      } else if (code === Result.UNKNOWN_ERROR) {
        const code = safeDeepGet(
          this.props,
          ['initCreateResult', 'code'],
          Result.UNKNOWN_ERROR
        );
      } else {
        this.showToastFailed();
      }
    }
    if (!isEdit) {
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        this.setState({ loading: false });
        if (code === Result.SUCCESS || code === Result.HTTP.OK) {
          this.showToast();
          this.props.history.replace('/account/list');
        } else if (code === Result.UNKNOWN_ERROR) {
          const code = safeDeepGet(
            this.props,
            ['createResult', 'code'],
            Result.UNKNOWN_ERROR
          );
        } else {
          this.showToastFailed();
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ['readResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const newState = {};
          const name = safeDeepGet(this.props, ['readResult', 'body', 'name']);
          newState['name'] = name;
          const email = safeDeepGet(this.props, [
            'readResult',
            'body',
            'email',
          ]);
          newState['email'] = email;
          const role = safeDeepGet(this.props, ['readResult', 'body', 'role']);
          newState['role'] = role;
          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } else if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.props.history.replace('/account/list');
        }
      }
    }
  }
  render() {
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Account', 'guest'], 'off');
    // const loading = safeDeepGet(this.props, "loading", true);
    const { isEdit, name, role, loading, email, pass, roleOptions } =
      this.state;
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>{buildTitle('Account Role')}</title>
            </MetaTags>
            <Container fluid={true}>
              <Breadcrumbs
                title="Account Role"
                breadcrumbItem={isEdit ? 'Edit' : 'Add'}
              />
              <Row>
                <Col xs="12">
                  <Card body>
                    <CardBody>
                      <CardTitle className="h4 text-center">
                        Account Information
                      </CardTitle>
                      <p className="card-title-desc text-center">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="3"></Col>
                        <Col sm="6">
                          <FormGroup className="mb-3 ajax-select select2-container">
                            <Label htmlFor="role">Role : </Label>

                            <Select
                              // value={role}
                              onChange={this.handelChangeRole}
                              options={roleOptions}
                              value={
                                isEdit
                                  ? roleOptions.filter(function (option) {
                                      return option.value === role;
                                    })
                                  : role
                              }
                              classNamePrefix="select2-selection"
                              isLoading={false}
                              placeholder="Select Role"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Name : </Label>
                            <AvField
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              placeholder="Masukan Nama..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Name',
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label className="mt-1" htmlFor="email">
                              Email :
                            </Label>
                            <AvField
                              type="email"
                              id="email"
                              name="email"
                              value={email}
                              disabled={isEdit}
                              placeholder="Masukan Email..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Email',
                                },
                              }}
                            />
                          </FormGroup>
                          {isEdit ? (
                            <></>
                          ) : (
                            <FormGroup className="mb-3">
                              <Label htmlFor="pass">Password : </Label>
                              <AvField
                                type="password"
                                id="pass"
                                name="pass"
                                value={pass}
                                placeholder="Masukan Password..."
                                className="form-control"
                                validate={{
                                  minLength: {
                                    value: 6,
                                    errorMessage:
                                      'Your name must be between 6 and 16 characters',
                                  },
                                  maxLength: {
                                    value: 16,
                                    errorMessage:
                                      'Your name must be between 6 and 16 characters',
                                  },
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter a Password',
                                  },
                                }}
                              />
                            </FormGroup>
                          )}
                        </Col>
                        <Col sm="3"></Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      to="/account/list"
                    >
                      Batal
                    </Link>{' '}
                    {guest == 'off' ? (
                      <Button type="submit" color="primary" disabled={loading}>
                        {!loading ? 'Submit' : <Spinner />}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['accountRole', 'initCreate'], {}),
  createResult: safeDeepGet(state, ['accountRole', 'create'], {}),
  loading: safeDeepGet(state, ['accountRole', 'loading'], true),
  readResult: safeDeepGet(state, ['accountRole', 'read'], {}),
  updateResult: safeDeepGet(state, ['accountRole', 'update'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitCreateAccount: () => dispatch(initCreate()),
  CreateAccount: payload => dispatch(create(payload)),
  ReadAccount: code => dispatch(read(code)),
  UpdateAccount: payload => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
