/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST_BUSINESS_POINTS = "TRANSACTION_LIST_BUSINESS_POINTS";
export const LIST = "TRANSACTION_LIST";
export const NEXT_LIST = "TRANSACTION_NEXT_LIST";
export const READ = "TRANSACTION_READ";
export const REFUND = "TRANSACTION_REFUND";
export const DOWNLOAD_DATA = "TRANSACTION_DOWNLOAD_DATA";
export const UPDATE_REFUND = "TRANSACTION_REFUND";
export const REFUNDED = "TRANSACTION_REFUNDED";
export const REFUND_LIST = "TRANSACTION_REFUND_LIST";
export const SNAPSHOT = "TRANSACTION_SNAPSHOT";
export const SUMMARY = "TRANSACTION_SUMMARY";
export const GET_SALES_ANALYTICS = "TRANSACTION_GET_SALES_ANALYTICS"
export const DETAIL = "TRANSACTION_DETAIL"
export const SNAPSHOT_DAILY = "TRANSACTION_SNAPSHOT_DAILY"
export const GET_REPORT_GROUP = "TRANSACTION_GET_REPORT_GROUP"
export const SEARCH_DATA = "TRANSACTION_SEARCH_DATA"
export const SNAPSHOT_MONTHLY = "TRANSACTION_SNAPSHOT_MONTHLY"