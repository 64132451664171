/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
// Router
// Table
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
import {
  NAME_PROJECT,
  buildTitle,
  APPLICATION_ID,
  getTableBusinessPoint,
  url_Calibration,
  url_Account,
  url_Planogram,
  url_Alert,
  url_Stock,
  url_Info,
  NAVBAR,
  PERMISION,
  url_options,
} from 'Apps';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { get_role } from '../../store/dashboard/actions';

// Redux
import { connect } from 'react-redux';
import {
  list,
  delete_,
  clear_cash,
  reset_flowmeter,
} from '../../store/business-point/actions';
// import BusinessPoint from "../../../Component/businessPoint"
const Category = [
  { label: 'All Category', value: 'all' },
  { label: 'Beverage', value: 'Beverage' },
  { label: 'Laundry', value: 'Laundry' },
];
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
import Pages403 from '../pages-403';
const navbar = JSON.parse(localStorage.getItem('accessNav'));
const permision = JSON.parse(localStorage.getItem('permision'));
class BusinessPoint extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const windowDimension = window.innerWidth;
    const isMobile = windowDimension <= 640;
    let role = '';
    const Columns = getTableBusinessPoint(
      role,
      isMobile,
      this.handleStockClick,
      this.handlePlanogramClick,
      this.handleInfoClick,
      this.handleCalibrationClick,
      this.handleAlertClick,
      this.handleDeleteClick,
      this.handleAccountClick,
      this.handleClearCashConfirm,
      this.handleOptionsClick,
      this.handleResetFlowConfirm
    );
    this.state = {
      isMobile,
      role: '',
      success_dlg: false,
      succes_clear: true,
      error_dlg: false,
      dynamic_title: '',
      device_id: '',
      dynamic_description: '',
      confirm_alert: false,
      reset_flowmeter: false,
      confirm_cash: false,
      success_cash: false,
      Columns,
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleInfoClick = this.handleInfoClick.bind(this);
    this.handleOptionsClick = this.handleOptionsClick.bind(this);
    this.handleCalibrationClick = this.handleCalibrationClick.bind(this);
    this.handlePlanogramClick = this.handlePlanogramClick.bind(this);
    this.handleAccountClick = this.handleAccountClick.bind(this);
    this.handleAlertClick = this.handleAlertClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleClearCashClick = this.handleClearCashClick.bind(this);
    this.handleResetFlowClick = this.handleResetFlowClick.bind(this);
    this.handleStockClick = this.handleStockClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleClearCashConfirm = this.handleClearCashConfirm.bind(this);
    this.handleResetFlowConfirm = this.handleResetFlowConfirm.bind(this);
  }

  handleDeleteConfirm(row) {
    this.setState({
      confirm_alert: true,
      device_id: row.id ?? row.device_id,
      name: row.name,
    });
  }
  handleClearCashConfirm = row => {
    this.setState({
      confirm_cash: true,
      device_id: row.device_id ?? row.id,
      name: row.name,
    });
  };
  handleResetFlowConfirm = row => {
    this.setState({
      reset_flowmeter: true,
      device_id: row.device_id ?? row.id,
      name: row.name,
    });
  };
  componentDidMount() {
    if (safeDeepGet(navbar, ['Business Point', 'edit'], 'off') == 'off') {
      this.state.Columns.pop();
    }
    const userData = JSON.parse(localStorage.getItem('authUser'));
    const emailLocal = safeDeepGet(userData, 'email', '');

    const email = localStorage.getItem('email') ?? emailLocal;
    this.props.GetRoleUser(email);
    // setInterval(async () => {
    // this.props.LoadBusinessPointList();
    // }, 3000);
  }
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/business-point/create');
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Businnes Point';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFlowMeter(msg) {
    var toastType = 'success';
    var message = 'Success Reset';
    if (msg == 'Failed') {
      toastType = 'error';
      message = 'Failed to Reset';
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastClearCash(msg) {
    var toastType = 'success';
    var message = 'Success Clear Cash';
    if (msg == 'Failed') {
      toastType = 'error';
      message = 'Failed to Clear Cash';
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleDeleteClick = data => {
    const row = {
      id: data.device_id,
      name: data.name,
    };
    this.props.DeleteBusinessPoint(row);
    this.props.LoadBusinessPointList();
    this.showToast();
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Deleted',
      dynamic_description: 'Business Point has been deleted.',
    });
  };
  handleClearCashClick = (id, name) => {
    const row = {
      device_id: id,
      device_name: name,
    };

    this.props.ClearCash(row);
  };
  handleResetFlowClick = (id, name) => {
    const row = {
      device_id: id,
      device_name: name,
    };
    this.setState({
      reset_flowmeter: false,
      success_dlg: true,
      dynamic_title: 'Reset FlowMeter',
      dynamic_description: 'Command Reset FlowMeter has been Send.',
    });
    this.props.ResetFlowmeter(row);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.clearResult !== prevProps.clearResult) {
      const code = safeDeepGet(
        this.props,
        ['clearResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        this.showToastClearCash('Success');
        this.setState({
          succes_clear: true,

          confirm_cash: false,
          success_dlg: true,
          dynamic_title: 'Clear Cash',
          dynamic_description: 'Command clear cash has been Success.',
        });
        this.props.LoadBusinessPointList();
      } else if (code == Result.FAILED) {
        this.showToastClearCash('Failed');
        this.setState({
          succes_clear: false,
          confirm_cash: false,
          success_dlg: true,
          dynamic_title: 'Clear Cash',
          dynamic_description: 'Command clear cash has been Failed.',
        });
      }
    }
    if (this.props.resetResult !== prevProps.resetResult) {
      const code = safeDeepGet(
        this.props,
        ['resetResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        this.showToastFlowMeter('Success');
        this.props.LoadBusinessPointList();
      } else if (code == Result.FAILED) {
        this.showToastFlowMeter('Failed');
      }
    }
    if (this.props.roleResult !== prevProps.roleResult) {
      const code = safeDeepGet(
        this.props,
        ['roleResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        this.props.LoadBusinessPointList();
      }
    }
  };

  handleOptionsClick = row => {
    const url = url_options(row);
    this.props.history.push(url);
  };
  handleInfoClick = row => {
    const url = url_Info(row);
    this.props.history.push(url);
  };
  handleStockClick = row => {
    const url = url_Stock(row);
    this.props.history.push(url);
  };
  handleAlertClick = row => {
    const url = url_Alert(row);
    this.props.history.push(url);
  };

  handlePlanogramClick = row => {
    const url = url_Planogram(row);
    this.props.history.push(url);
  };

  handleAccountClick = row => {
    const url = url_Account(row);
    this.props.history.push(url);
  };

  handleCalibrationClick = row => {
    const url = url_Calibration(row);
    this.props.history.push(url);
  };
  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'name',
        order: 'asc',
      },
    ];

    const { SearchBar } = Search;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Business Point')}</title>
          </MetaTags>
          {safeDeepGet(navbar, ['Business Point', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Business Point" breadcrumbItem="List" />
              {this.state.success_dlg ? (
                this.state.succes_clear ? (
                  <SweetAlert
                    success
                    title={this.state.dynamic_title}
                    onConfirm={() => {
                      setTimeout(() => {
                        this.props.LoadBusinessPointList();
                      }, 2000);
                      this.setState({ success_dlg: false });
                    }}
                  >
                    {this.state.dynamic_description}
                  </SweetAlert>
                ) : (
                  <SweetAlert
                    danger 
                    title={this.state.dynamic_title}
                    onConfirm={() => {
                      setTimeout(() => {
                        this.props.LoadBusinessPointList();
                      }, 2000);
                      this.setState({ success_dlg: false });
                    }}
                  >
                    {this.state.dynamic_description}
                  </SweetAlert>
                )
              ) : null}
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={this.state.Columns}
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={this.state.Columns}
                            data={list}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <Col sm="3">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    {/* <div >
                                    <FormGroup className="mb-3 ajax-select select2-container">
                                      <Select
                                        defaultValue={Category[0]}
                                        // value={businessPoints}
                                        // onChange={this.handleBusinessPoints}
                                        options={Category}
                                        classNamePrefix="select2-selection"
                                        isLoading={false}
                                        placeholder="Business Point"
                                      />
                                    </FormGroup>
                                    </div> */}
                                  </Col>
                                  <Col sm="7">
                                    {safeDeepGet(
                                      navbar,
                                      ['Business Point', 'edit'],
                                      'off'
                                    ) == 'off' ? null : (
                                      <div className="text-sm-end">
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded mb-2 me-2"
                                          href="/business-point/create"
                                        >
                                          <i className="mdi mdi-plus me-1" />{' '}
                                          New Business Point
                                        </Button>
                                      </div>
                                    )}
                                  </Col>
                                </Row>

                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={this.node}
                                  />
                                </div>

                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                {this.state.confirm_alert ? (
                                  <SweetAlert
                                    title="Are you sure?"
                                    warning
                                    showCancel
                                    confirmButtonText="Yes, delete it!"
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteClick(
                                        this.state.device_id,
                                        this.state.name
                                      )
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        confirm_alert: false,
                                      })
                                    }
                                  >
                                    You won&apos;t be able to revert this!
                                  </SweetAlert>
                                ) : null}
                                {this.state.confirm_cash ? (
                                  <SweetAlert
                                    title="Are you sure?"
                                    warning
                                    showCancel
                                    confirmButtonText="Yes, Clear it!"
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleClearCashClick(
                                        this.state.device_id,
                                        this.state.name
                                      )
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        confirm_cash: false,
                                      })
                                    }
                                  >
                                    You won&apos;t be able to revert this!
                                  </SweetAlert>
                                ) : null}
                                {this.state.reset_flowmeter ? (
                                  <SweetAlert
                                    title="Are you sure?"
                                    warning
                                    showCancel
                                    confirmButtonText="Yes, Clear it!"
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleResetFlowClick(
                                        this.state.device_id,
                                        this.state.name
                                      )
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        reset_flowmeter: false,
                                      })
                                    }
                                  >
                                    You won&apos;t be able to revert this!
                                  </SweetAlert>
                                ) : null}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['businessPoint', 'list'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  clearResult: safeDeepGet(state, ['businessPoint', 'clear_cash'], {}),
  resetResult: safeDeepGet(state, ['businessPoint', 'reset_flowmeter'], {}),
  deleteResult: safeDeepGet(state, ['businessPoint', 'delete_'], {}),
  roleResult: safeDeepGet(state, ['dashboard', 'get_role'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadBusinessPointList: () => dispatch(list()),
  LoadBusinessPointFillter: code => dispatch(list(code)),
  DeleteBusinessPoint: payload => dispatch(delete_(payload)),
  ClearCash: payload => dispatch(clear_cash(payload)),
  ResetFlowmeter: payload => dispatch(reset_flowmeter(payload)),
  GetRoleUser: payload => dispatch(get_role(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPoint);
