/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
 
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';
export default class Category extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'category');
  }
  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };
  delete_ = id => {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(id)
        .delete()
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  create = payload => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (key === 'id' || key === 'category') {
          insertedPayload[key] = payload[key];
        }
      }
      const id = payload['id'];
      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}
