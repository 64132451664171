/* eslint-disable semi */
/* Import */
import { optBoolean, optString } from 'iotera-base/utility/object';

/* Application id */


//TODO PROJECT_HANDLE || APPLICATION_ID
//TODO Dev            || 1000000021
//TODO Redboks        || 1000000209
//TODO Demo           || 1000000021
//TODO Nestle         || 1000000159
//TODO Franke         || 1000000211
//TODO Indesso        || 1000000226
//TODO Fuji           || 1000000021 / 1000000229 (VendKios)
//TODO FujiMinex      || 1000000229
//TODO FujiSoltova    || 1000000230
//TODO Hamparan       || 1000000232
//TODO TTM            || 1000000233
//TODO Inaco          || 1000000238

/* Default */
export const DEFAULT_PLATFORM_URL = optString(
  window._env_.DEFAULT_PLATFORM_URL,
  ''
);
export const DEFAULT_FIREBASE_URL = optString(
  window._env_.DEFAULT_FIREBASE_URL,
  ''
);

/* Multi-tenancy */
export const MULTITENANCY = optBoolean(window._env_.MULTITENANCY, false);
export const ENV_PROJECT_HANDLE = MULTITENANCY ? localStorage.getItem('project_handle')  : optString(window._env_.PROJECT_HANDLE, '') 
export const ENV_APPLICATION_ID = MULTITENANCY ? localStorage.getItem('application_id')  :  optString(
  window._env_.APPLICATION_ID,
  '1000000209'
);

export const MULTITENANCY_PLATFORM_URL = optString(
  window._env_.MULTITENANCY_PLATFORM_URL,
  ''
);
export const MULTITENANCY_FIREBASE_URL = optString(
  window._env_.MULTITENANCY_FIREBASE_URL,
  ''
);

export const SERVERLESS = optBoolean(
  window._env_.SERVERLESS, true
)
