/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import firebase from 'firebase/compat/app';
import { APPLICATION_ID } from 'Apps';
import { safeDeepDel, safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class BusinessPoint extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'business_point');
  }

  list = payload => {
    return new Promise((resolve, reject) => {
      var code = safeDeepGet(payload, 'code');
      var type = safeDeepGet(payload, 'type');
      // var code = 'pt_cahaya_inti_putra_sejahtera'
      // var type = 'distributor';
      if (code == 'all' || code == null) {
        code = null;
      }
      if (type == 'all' || type == null) {
        type = null;
      }
      let query = this.collection;
      if (type || code) {
        query = query.where(type, '==', code);
      }
      const map = {};
      query.orderBy("name").get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };

  map = () => {
    return this.list();
  };

  create = (id, payload) => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'address' ||
          key === 'cp' ||
          key === 'device_id' ||
          key === 'email' ||
          key === 'name' ||
          key === 'pic' ||
          key === 'type' ||
          key === 'sn'
        ) {
          insertedPayload[key] = payload[key];
        }
      }
      const date = new Date()
      insertedPayload['tags'] = payload["tags"] ?? "-";
      insertedPayload['created_date'] = date.getTime();
      insertedPayload['active_date'] = date.getTime();
      const sn = payload['sn']; 
      const end = sn.length;
      const start = end - 4;
      const codesn = sn.substring(start, end);
      const endapp = APPLICATION_ID.length;
      const startapp = endapp - 4;
      const appSn = APPLICATION_ID.substring(startapp, endapp);
      const vmcode = appSn + '-' + codesn;
      insertedPayload['vmcode'] = vmcode;
      // Add location
      if ('latitude' in payload && 'longitude' in payload) {
        insertedPayload['loc'] = new firebase.firestore.GeoPoint(
          payload['latitude'],
          payload['longitude']
        );
      }

      // Add device id
      insertedPayload['device_id'] = id;

      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = deviceId => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('device_id', '==', deviceId)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };
  list_tags = (tags, allBp, arr_bp) => {
    return new Promise((resolve, reject) => {
      if (tags == 'all' || tags == null) {
        tags = null;
      }
      let query = this.collection;
      if (tags) {
        query = query.where('tags', '==', tags);
      } else {
        if(allBp == 'false' || allBp == false){
          query = query.where('device_id', 'in', arr_bp);
        } 
      }
      const listBp = [];
      query
        .get()
        .then(
          querySnapshot => {
            querySnapshot.docs.map(doc => {
              listBp.push(doc.id)
            });
            resolve({ code: 0, body: listBp });
          },
          error => {
            console.log(error)
            resolve({ code: -1, body: listBp, error: error });
          }
        );
    });
  };
  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'address' ||
          key === 'cp' ||
          key === 'device_id' ||
          key === 'email' ||
          // key === 'name' ||
          key === 'type' ||
          key === 'pic' ||
          key === 'sn'
        ) {
          updatedPayload[key] = payload[key];
        }
      }
      updatedPayload['tags'] = payload["tags"] ?? "-";

      const sn = payload['sn'];
      const end = sn.length;
      const start = end - 4;
      const codesn = sn.substring(start, end);
      const endapp = APPLICATION_ID.length;
      const startapp = endapp - 4;
      const appSn = APPLICATION_ID.substring(startapp, endapp);
      const vmcode = appSn + '-' + codesn;
      // updatedPayload['vmcode'] = vmcode;
      const refill = safeDeepGet(payload, 'refiller', '');
      updatedPayload['refiller'] = refill;
      // Add location
      if ('latitude' in payload && 'longitude' in payload) {
        updatedPayload['loc'] = new firebase.firestore.GeoPoint(
          payload['latitude'],
          payload['longitude']
        );
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = deviceId => {
    this.collection.doc(deviceId).delete();
  };

  duplicate = (id, newId) => {};
  updateFee = data => {
    return new Promise((resolve, reject) => {
      const id = safeDeepGet(data, 'id', '');
      safeDeepDel(data, 'id');
      this.collection
        .doc(id)
        .update({fee: data})
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}
