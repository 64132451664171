/* eslint-disable no-unused-vars */

/* eslint-disable semi */

/* Import */
import firebase from 'firebase/compat/app';
import Base from './base';

export default class Subscription extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'subscription');
  }

  list = async () => {
    try {
      const querySnapshot = await this.collection.get();
      const list = querySnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});
      return { code: 0, body: list };
    } catch (error) {
      return { code: -1, body: error };
    }
  };

  create = async (id, payload, url, price, PO, duration, bp) => {
    const insertedPayload = {
      name: payload.name,
      status: 'Create',
      type: 'Create',
      order_id: id,
      url,
      key: PO,
      duration,
      price,
      business_point: bp,
      created_time: new Date().getTime(),
    };
    try {
      await this.collection.doc(id).set(insertedPayload);
      return { code: 0 };
    } catch (error) {
      return { code: -1, body: error };
    }
  };

  extended = async (id, payload, url, price, PO, duration, bp, old_key) => {
    const insertedPayload = {
      name: payload.name,
      old_key,
      status: 'Extended',
      type: 'Extended',
      order_id: id,
      url,
      key: PO,
      duration,
      price,
      business_point: bp,
      extended_time: new Date().getTime(),
    };
    try {
      await this.collection.doc(id).set(insertedPayload);
      return { code: 0 };
    } catch (error) {
      return { code: -1, body: error };
    }
  };

  read = async sku => {
    try {
      const querySnapshot = await this.collection.where('sku', '==', sku).get();
      if (querySnapshot.size > 0) {
        const map = { ...querySnapshot.docs[0].data() };
        return { code: 0, body: map };
      } else {
        return { code: -1, body: {} };
      }
    } catch (error) {
      return { code: -1, body: error };
    }
  };

  delete_ = id => {
    this.collection.doc(id).delete();
  };

  checkPayment = async id => {
    try {
      const snapshot = await this.collection.where('__name__', '==', id).get();
      const list = snapshot.docs.map(doc => doc.data());
      return list.length < 1 ? { code: -1 } : { code: 0, body: list };
    } catch (error) {
      console.log(error);
      return { code: -1, body: [] };
    }
  };
}
