/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  Label,
  Table,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';

//Import Breadcrumb

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//CSV File
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
// Redux
import { connect } from 'react-redux';
import { inputCsv } from '../../store/business-point/actions';
import {
  list,
  create,
  listAll,
  setAll,
  delete_,
  update,
} from '../../store/rfid/actions';
import { NAVBAR, PERMISION, buildTitle } from 'Apps';
const navbar = NAVBAR;
import Pages403 from 'Base/pages/pages-403';
import { updateRFID } from 'Apps/device';

const fileReader = new FileReader();
class RFIDList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const path = this.props.match.path;
    const deviceId = safeDeepGet(this.props.match.params, 'id');
    this.state = {
      modalImport: false,
      modalAdd: false,
      modal: false,
      isEdit: false,
      deviceId: deviceId,
      bpfilterlist: [],
      businessPoints: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      file: '',
      array: [],

      headerTable: '',
      valuesArray: [],
      formValues: [],
      headerValues: [],
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggleImport() {
    this.setState(prevState => ({
      modalImport: !prevState.modalImport,
    }));
  }
  handleImportClick = row => {
    this.toggleImport();
    this.toggle();
  };
  toggleAdd() {
    this.setState(prevState => ({
      modalAdd: !prevState.modalAdd,
    }));
  }
  handleAddClick = (row, type) => {
    if (type == 'edit') {
      this.setState(prevState => ({
        isEdit: !prevState.isEdit,
        rfid: row,
      }));
    }
    this.toggleAdd();
    this.toggle();
  };
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleClick = row => {
    this.toggle();
  };
  handleDeleteClick = row => {
    this.props.DeleteRFID(row);
  };
  handleResetClick = (row, type) => {
    let data = [];
    if (type == 'one') {
      data.push(row);
    } else {
      data = this.state.formValues;
    }
    this.props.UpdateRFID(data);
  };
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  componentDidMount() {
    const { deviceId } = this.state;
    if (deviceId == undefined) {
      this.props.ListAll();
    } else {
      this.props.List(deviceId);
    }
  }

  showToast(message, toastType) {
    const { isEdit } = this.state;
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          this.showToast('Success Add', 'success');
          // this.handleImportClick();
          this.toggleAdd();
          this.componentDidMount();
          // this.props.history.replace('/ingredient/list');
          // window.location.reload();
        } else {
          this.showToast('Failed Add', 'error');
        }
      }
    }
    if (this.props.setAllResult !== prevProps.setAllResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['setAllResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          this.showToast('Success Import', 'success');
          // this.handleImportClick();
          this.toggleImport();
          this.componentDidMount();
          // this.props.history.replace('/ingredient/list');
          // window.location.reload();
        } else {
          this.showToast('Failed Import', 'error');
        }
      }
    }
    if (this.props.deleteRfid !== prevProps.deleteRfid) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['deleteRfid', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          this.showToast('Success Delete', 'success');
          this.componentDidMount();
          // this.props.history.replace('/ingredient/list');
          // window.location.reload();
        } else {
          this.showToast('Failed Delete', 'error');
        }
      }
    }
    if (this.props.updateRfidResult !== prevProps.updateRfidResult) {
      const {isEdit} = this.state
      // Success create
      const code = safeDeepGet(
        this.props,
        ['updateRfidResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          if(isEdit){
            this.showToast('Success Edit', 'success');
            this.componentDidMount();
            this.handleAddClick();
            this.setState(prevState => ({
              isEdit: false,
              rfid: {},
            }));
          }else {

            this.showToast('Success Reset', 'success');
            this.componentDidMount();
          }
          // this.props.history.replace('/ingredient/list');
          // window.location.reload();
        } else {
          if(isEdit){
            this.showToast('Failed Edit', 'error');

            this.componentDidMount();
          }else {

            this.showToast('Failed Reset', 'error');

          }
        }
      }
    }
    if (this.props.readRFID !== prevProps.readRFID) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['readRFID', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS || code == Result.HTTP.OK) {
        const body = safeDeepGet(this.props, ['readRFID', 'body', 'user'], {});
        this.setState({ formValues: body });
      }
    }
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handleOnChange = e => {
    const file = e.target.files[0];
    this.setState({ file });
    // let valuesArray =[]
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        const valuesArray = results['data'];
        const updatedValuesArray = valuesArray
          .map(d => ({
            ...d,
            rule: d.rule ? parseInt(d.rule, 10) : null, // Convert 'rule' to integer, or set to null if it's not a number
          }))
          .filter(d => d.id !== ''); // Remove objects where 'id' is an empty string

        const headerKeys = Object.keys(
          Object.assign({}, ...updatedValuesArray)
        );
        const dataheader = {};
        const header = [];
        for (const id in headerKeys) {
          const data = headerKeys[id];
          safeDeepSet(dataheader, [id, 'text'], data);
          safeDeepSet(dataheader, [id, 'dataField'], data);

          // set formatter text in table
          if (data == 'imgUrl') {
            safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
              <>
                <p className="text-wrap">{row.imgUrl}</p>
              </>
            ));
          }
          if (data == 'description') {
            safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
              <>
                <p className="text-wrap">{row.description}</p>
              </>
            ));
          }
          if (data != 'quota') {
            header.push(dataheader[id]);
          }
        }
        // Set the updated array in the state
        this.setState({
          valuesArray: updatedValuesArray,
          headerValues: header,
        });
        const rowsArray = [];
        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d));
        });

        this.setState({ rowsArray });
      }.bind(this),
    });
  };

  handleSubmit() {
    const payload = {
      rfid: this.state.valuesArray,
    };
    this.props.SetCsv(payload);
    this.setState({ valuesArray: [] });
  }

  handleValidSubmit(event, values) {
    const payload = {
      ...values,
      rule: Number(values['rule']),
    };
    const { isEdit } = this.state;
    if (isEdit) {
      const data = [];
      data.push(payload)
      this.props.UpdateRFID(data);
    } else {
      this.props.AddRFID(payload);
    }
  }

  render() {
    const { deviceId, valuesArray, formValues, rfid, isEdit } = this.state;
    const defaultSorted = [
      {
        dataField: 'year', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const headerKeys = Object.keys(Object.assign({}, ...formValues));
    const dataheader = {};
    const header = [];
    for (const id in headerKeys) {
      const data = headerKeys[id];
      safeDeepSet(dataheader, [id, 'text'], data);
      safeDeepSet(dataheader, [id, 'dataField'], data);

      // set formatter text in table
      if (data == 'imgUrl') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.imgUrl}</p>
          </>
        ));
      }
      if (data == 'description') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.description}</p>
          </>
        ));
      }

      if (data != 'quota') {
        header.push(dataheader[id]);
      }
    }
    header.push({
      dataField: 'action',
      isDummyField: true,
      editable: false,
      text: 'Action',
      formatter: (cellContent, row) => (
        <div className="d-flex gap-2">
          <Tippy content={'Edit'}>
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pen font-size-15"
                id="resettooltip"
                onClick={() => this.handleAddClick(row, 'edit')}
              ></i>
            </Link>
          </Tippy>
          <Tippy content={'Reset'}>
            <Link className="text-info" to="#">
              <i
                className="mdi mdi-refresh font-size-15"
                id="resettooltip"
                onClick={() => this.handleResetClick(row, 'one')}
              ></i>
            </Link>
          </Tippy>
          <Tippy content={'Delete'}>
            <Link className="text-danger" to="#">
              <i
                className="mdi mdi-delete font-size-15"
                id="deletetooltip"
                onClick={() => this.handleDeleteClick(row)}
              ></i>
            </Link>
          </Tippy>
        </div>
      ),
    });
    const nameBP = safeDeepGet(
      this.props,
      ['readRfidResult', 'body', 'name'],
      ''
    );
    const headers = [
      {
        label: 'id',
        key: 'id',
      },
      {
        label: 'rule',
        key: 'rule',
      },
      {
        label: 'name',
        key: 'name',
      },
    ];
    const separator = ','; //if want csv File using commma change with (',') or something else
    const csvLink = {
      headers: headers,
      data: formValues,
      separator: separator,
      filename: 'RFID ' + nameBP + '.csv',
    };
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25, //Change if to much
      totalSize: formValues.length, // replace later with size,
      custom: true,
    };
    return (
      <React.Fragment>
        {safeDeepGet(navbar, ['RFID', 'view'], 'off') == 'off' ? (
          <Pages403 />
        ) : (
          <div className="page-content">
            <MetaTags>
              <title>{buildTitle('Import & Export RFID')}</title>
            </MetaTags>
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs="8">
                          {safeDeepGet(navbar, ['RFID', 'edit'], 'off') ==
                          'off' ? (
                            <></>
                          ) : (
                            <div className="text-sm-end">
                              <Button
                                className="btn btn-primary white "
                                onClick={() => this.handleResetClick({}, 'all')}
                              >
                                Reset All
                              </Button>
                            </div>
                          )}
                        </Col>
                        <Col xs="2">
                          {safeDeepGet(navbar, ['RFID', 'edit'], 'off') ==
                          'off' ? (
                            <></>
                          ) : (
                            <div className="text-sm-center">
                              <CSVLink
                                {...csvLink}
                                className="btn btn-primary white mb-3"
                              >
                                Export to CSV
                              </CSVLink>
                            </div>
                          )}
                        </Col>
                        <Col xs="2">
                          {safeDeepGet(navbar, ['RFID', 'edit'], 'off') ==
                          'off' ? (
                            <></>
                          ) : (
                            <div className="text-sm-start">
                              <Button
                                className="btn btn-primary white "
                                onClick={this.handleClick}
                                // onClick={this.handleImportClick}
                              >
                                Add RFID
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <br />

                        {formValues.length == 0 ? null : (
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={header}
                            data={formValues}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={header}
                                data={formValues}
                                search
                              >
                                {toolkitprops => (
                                  <React.Fragment>
                                    <Row className="mb-2"></Row>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              'table align-middle table-nowrap table-hover'
                                            }
                                            bordered={true}
                                            striped={true}
                                            responsive
                                            ref={this.node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}

                        <Modal
                          size="lg"
                          isOpen={this.state.modal}
                          className={this.props.className}
                        >
                          <ModalHeader toggle={this.toggle} tag="h4">
                            {'Add RFID'}
                          </ModalHeader>
                          <ModalBody>
                            <Row>
                              <Col sm={6} className="text-end">
                                <Button
                                  className="btn btn-primary white "
                                  onClick={this.handleAddClick}
                                >
                                  Add Single RFID
                                </Button>
                              </Col>
                              <Col sm={6} className="text-start">
                                <Button
                                  className="btn btn-primary white "
                                  onClick={this.handleImportClick}
                                >
                                  Import CSV
                                </Button>
                              </Col>
                            </Row>
                          </ModalBody>
                        </Modal>

                        <Modal
                          size="lg"
                          isOpen={this.state.modalImport}
                          className={this.props.className}
                        >
                          <ModalHeader toggle={this.toggleImport} tag="h4">
                            {'Import RFID'}
                          </ModalHeader>
                          <ModalBody>
                            <Row className="mb-3">
                              <Col xs="7">
                                <div className="text-sm-center">
                                  <input
                                    className="form-control"
                                    type="file"
                                    accept=".csv"
                                    id="csvFile"
                                    onChange={this.handleOnChange}
                                  ></input>
                                </div>
                              </Col>
                              <Col xs="5">
                                <div className="text-sm-center">
                                  <Button
                                    className="btn btn-primary white "
                                    onClick={this.handleSubmit}
                                  >
                                    Import CSV
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              {valuesArray.length == 0 ? null : (
                                <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField="id"
                                  columns={this.state.headerValues}
                                  data={valuesArray}
                                >
                                  {({
                                    paginationProps,
                                    paginationTableProps,
                                  }) => (
                                    <ToolkitProvider
                                      keyField="id"
                                      columns={this.state.headerValues}
                                      data={valuesArray}
                                      search
                                    >
                                      {toolkitprops => (
                                        <React.Fragment>
                                          <Row className="mb-2"></Row>
                                          <Row>
                                            <Col xl="12">
                                              <div className="table-responsive">
                                                <BootstrapTable
                                                  {...toolkitprops.baseProps}
                                                  {...paginationTableProps}
                                                  defaultSorted={defaultSorted}
                                                  classes={
                                                    'table align-middle table-nowrap table-hover'
                                                  }
                                                  bordered={true}
                                                  striped={true}
                                                  responsive
                                                  ref={this.node}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row className="align-items-md-center mt-30">
                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                              <PaginationListStandalone
                                                {...paginationProps}
                                              />
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                              )}
                            </Row>
                          </ModalBody>
                        </Modal>
                        <Modal
                          size="lg"
                          isOpen={this.state.modalAdd}
                          className={this.props.className}
                        >
                          <ModalHeader toggle={this.toggleAdd} tag="h4">
                            {isEdit ? 'Edit RFID' : 'Add RFID'}
                          </ModalHeader>
                          <ModalBody>
                            <Row>
                              <AvForm onValidSubmit={this.handleValidSubmit}>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col>
                                        <Label className="mt-1" htmlFor="id">
                                          RFID ID :
                                        </Label>
                                        <AvField
                                          type="text"
                                          id="id"
                                          name="id"
                                          disabled={isEdit}
                                          value={safeDeepGet(rfid, 'id', '')}
                                          placeholder="Masukan RFID ID..."
                                          className="form-control"
                                          validate={{
                                            required: {
                                              value: true,
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col>
                                        <Label className="mt-1" htmlFor="id">
                                          Name RFID :
                                        </Label>
                                        <AvField
                                          type="text"
                                          id="name"
                                          name="name"
                                          value={safeDeepGet(rfid, 'name', '')}
                                          placeholder="Masukan RFID Name..."
                                          className="form-control"
                                          validate={{
                                            required: {
                                              value: true,
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col>
                                        <Label className="mt-1" htmlFor="id">
                                          Rule RFID :
                                        </Label>
                                        <AvField
                                          type="number"
                                          id="rule"
                                          name="rule"
                                          value={safeDeepGet(rfid, 'rule', '')}
                                          placeholder="Masukan Limit RFID..."
                                          className="form-control"
                                          validate={{
                                            required: {
                                              value: true,
                                            },
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                                <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                  <Button
                                    color="secondary"
                                    onClick={() =>
                                      this.handleAddClick(
                                        [],
                                        isEdit ? 'edit' : ''
                                      )
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button type="submit" color="primary">
                                    Simpan
                                  </Button>
                                </div>
                              </AvForm>
                            </Row>
                          </ModalBody>
                        </Modal>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: safeDeepGet(state, ['rfid', 'loading'], true),
  createResult: safeDeepGet(state, ['rfid', 'create'], {}),
  setAllResult: safeDeepGet(state, ['rfid', 'setAll'], {}),
  readRfidResult: safeDeepGet(state, ['rfid', 'list'], {}),
  readRFID: safeDeepGet(state, ['rfid', 'listAll'], {}),
  deleteRfid: safeDeepGet(state, ['rfid', 'delete'], {}),
  updateRfidResult: safeDeepGet(state, ['rfid', 'update'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  DeleteRFID: payload => dispatch(delete_(payload)),
  UpdateRFID: payload => dispatch(update(payload)),
  List: id => dispatch(list(id)),
  ListAll: () => dispatch(listAll()),
  AddRFID: payload => dispatch(create(payload)),
  SetCsv: payload => dispatch(setAll(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RFIDList);
