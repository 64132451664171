export const LOADING = 'LOADING';
export const RESULT = 'RESULT';

export const LIST = 'COUPON_LIST';
export const INIT_CREATE = 'COUPON_INIT_CREATE';
export const CREATE = 'COUPON_CREATE';
export const CANCEL = 'COUPON_CANCEL';
export const READ = 'COUPON_INFO';
export const UPDATE = 'COUPON_UPDATE';
export const STOCK_UPDATE = 'STOCK_COUPON_UPDATE';
export const DELETE = 'COUPON_DELETE';
export const DETAIL_CHAT = 'DETAIL_COUPON';
